exports.components = {
  "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-pages-storyblok-preview-js": () => import("./../../../../../node_modules/@yumgmbh/gatsby-theme-yum-storyblok/src/pages/storyblok-preview.js" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-pages-storyblok-preview-js" */),
  "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-templates-storyblok-default-template-js": () => import("./../../../../../node_modules/@yumgmbh/gatsby-theme-yum-storyblok/src/templates/StoryblokDefaultTemplate.js" /* webpackChunkName: "component---node-modules-yumgmbh-gatsby-theme-yum-storyblok-src-templates-storyblok-default-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-de-login-information-js": () => import("./../../../src/pages/de-de/login-information.js" /* webpackChunkName: "component---src-pages-de-de-login-information-js" */),
  "component---src-pages-de-de-produkte-js": () => import("./../../../src/pages/de-de/produkte.js" /* webpackChunkName: "component---src-pages-de-de-produkte-js" */),
  "component---src-pages-demo-1-jsx": () => import("./../../../src/pages/demo/1.jsx" /* webpackChunkName: "component---src-pages-demo-1-jsx" */),
  "component---src-pages-demo-2-jsx": () => import("./../../../src/pages/demo/2.jsx" /* webpackChunkName: "component---src-pages-demo-2-jsx" */),
  "component---src-pages-demo-index-jsx": () => import("./../../../src/pages/demo/index.jsx" /* webpackChunkName: "component---src-pages-demo-index-jsx" */),
  "component---src-pages-demo-test-jsx": () => import("./../../../src/pages/demo-test.jsx" /* webpackChunkName: "component---src-pages-demo-test-jsx" */),
  "component---src-pages-demo-workspace-jsx": () => import("./../../../src/pages/demo/workspace.jsx" /* webpackChunkName: "component---src-pages-demo-workspace-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/ProductTemplate.js" /* webpackChunkName: "component---src-templates-product-template-js" */)
}

